import React from 'react';
import { string, bool, oneOfType, node } from 'prop-types';
import cx from 'classnames';

import checkedBoxOutline from '../../images/checked-box-outline.svg';

const propTypes = {
  text: oneOfType([string, node]),
  uri: string,
  isOrange: bool,
};

const defaultProps = {
  text: undefined,
  uri: undefined,
  isOrange: false,
};

const IconListItem = ({ text, uri, isOrange }) => {
  const spanClassName = cx({
    'font-size-sm text-orange': isOrange,
    'font-size-sm text-gray-700': !isOrange,
  });

  return (
    <li className="d-flex align-items-start gap-2">
      <img src={checkedBoxOutline} alt="" />
      {uri ? (
        <a href={uri} className={spanClassName}>
          {text}
        </a>
      ) : (
        <span className={spanClassName}>{text}</span>
      )}
    </li>
  );
};

IconListItem.propTypes = propTypes;
IconListItem.defaultProps = defaultProps;

export default IconListItem;
